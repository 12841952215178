@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gothic';
  src: url('./assets/fonts/gothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brand {
  font-family: 'Gothic', sans-serif;
}

.underline-blue {
  text-decoration-color: #2563EB;
}