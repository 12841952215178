.menu-open .menu-background {
    width: 400vw; /* Ensure it covers the entire screen */
    height: 600vw; /* Ensure it covers the entire screen */
    border-radius: 50%;
    transform: scale(1);
    top: -300vw;
    right: -200vw;
}

.menu-close .menu-background {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: scale(0);
    top: 0;
    right: 0;
}